.contactContent {
  font-size: 20px;
}

.contactLink {
  display: block;
  text-align: center;
  padding: 5px;
}

ul {
  list-style-type: none;
}


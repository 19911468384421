@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@600&display=swap");
html {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2e3440;
  color: #e5e9f0;
  font-family: "JetBrains Mono", monospace;
}

.contactContainer h1 {
  text-align: center;
}

.About {
  width: 700px;
  position: relative;
  margin: auto;
}

h1 {
  text-align: center;
}

h2 {
  text-align: center;
}

h3 {
  text-align: center;
  margin-top: 15px;
}

.projectsWrapper {
  padding: auto;
}

.Project {
  background-color: #3b4252;
  border-radius: 15px;
  height: 250px;
  text-align: center;
}

.info-button {
  color: #e5e9f0;
  width: 150px;
  height: 50px;
  background-color: #88c0d0;
  border-radius: 15px;
  border-style: none;
}

.return-button {
  text-align: center;
  color: #e5e9f0;
  width: 225px;
  height: 75px;
  background-color: #88c0d0;
  border-radius: 15px;
  border-style: none;
}

p {
  text-align: center;
}

.InvalidPage {
  text-align: center;
}


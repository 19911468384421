@import "theme.scss";

html {
  background-color: $nord0;
}

a {
  text-decoration: none;
  color: $nord5;
}

.Navbar {
  background-color: $nord0;
  display: flex;
  align-items: center;
}

.Navbar ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.Navbar h1 {
  display: block;
  color: $nord5;
  padding: 0 24px;
}

.Navbar ul li a {
  padding: 12px;
  display: block;
}

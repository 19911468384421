@import "theme.scss";
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@600&display=swap");
// with  more than 1080
$background: $nord0;
$textColor: $nord5;
@media(max-width:1080px)  {
  
}

@media(min-width: 1080px) {}
html {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $background;
  color: $textColor;
  font-family: "JetBrains Mono", monospace;
}
div {
}

.contactContainer {
}

.contactContainer h1 {
  text-align: center;
}

.About {
  width: 700px;
  position: relative;
  margin: auto;
}
h1 {
  text-align: center;
}
h2 {
  text-align: center;
}
h3 {
  text-align: center;
  margin-top: 15px;
}
.projectsWrapper {
  padding: auto;
}
.Project {
  background-color: $nord1;
  border-radius: 15px;
  height: 250px;
  text-align: center;
}

.info-button {
  color: $nord5;
  width: 150px;
  height: 50px;
  background-color: $nord8;
  border-radius: 15px;
  border-style: none;
}

.return-button {
  
  text-align: center;
  color: $nord5;
  width: 225px;
  height: 75px;
  background-color: $nord8;
  border-radius: 15px;
  border-style: none;
}
section {
}

p {
  text-align: center;
}

.InvalidPage {
  text-align: center;
}
